// React
import React, {
    createContext,
    useReducer,
} from 'react';
import { useNavigate } from "react-router-dom";

// Other Imports
import AppReducer from './AppReducer';

// Helpers
import api from 'helpers/api';

let initialState = {
    loading: false,
    user: {
        id: null,
        first_name: null,
        last_name: null,
        avatar_seed: null,
        photo: null,
        photo_thumb: null,
        licensee_user_roles: []
    },
    licensee: {
        id: null,
        loading: true,
        sites: [],
        sitemap_licenses: []
    },
    site: {
        id: null,
        display_id: null,
        loading: true
    },
    sitemap: {
        loading: true,
        data: {
//            site_items: {},
//            children: {}
        }
    }
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const navigate = useNavigate();

    // Sitemap specific functions
    function addSiteMapTier(tier) {
        dispatch({
            type: 'ADD_SITEMAP_TIER',
            tier: tier
        });
    };

    function removeSiteMapTier(field) {
        dispatch({
            type: 'DELETE_SITEMAP_TIER',
            field: field
        });
    };

    function removeSiteMapSiteItem(tier, site_item) {
        dispatch({
            type: 'DELETE_SITEMAP_SITE_ITEM',
            tier: tier,
            site_item: site_item
        });
    };

    // Non-Specific update function for any field
    function updateGlobalStateItem(field, value) {
        dispatch({
            type: 'UPDATE',
            field: field,
            payload: value
        });
    };

    // User Functions
    function logUserIn(data, updateMyLicensees = false) {
        dispatch({
            type: 'LOGIN',
            payload: data,
            updateMyLicensees: updateMyLicensees
        });
    }
    function logUserOut() {
        dispatch({
            type: 'LOGOUT'
        });
    }

    // Licensee Functions
    async function selectLicensee(user_id, licensee_id, redirect = true, unset_site = true) {
        updateGlobalStateItem('licensee.loading', true);
        // Fetch Licensee Details
        await api.post("licensees/fetch",{
            body: JSON.stringify({
               user_id: user_id,
               licensee_id: licensee_id
            })
        })
        .then(data => {
            // Store In State
            data.result.licensee.loading = false;
            updateGlobalStateItem('licensee', data.result.licensee);
            updateGlobalStateItem('user.last_accessed_licensee_id', licensee_id);
            // If Licensee is still on a Free Trial (i.e. they have NO SitemapLicense), redirect to the License screen when required
            if (data.result.licensee.sitemap_licenses.length === 0 && redirect) {
                updateGlobalStateItem('licensee.loading', false);
                return navigate('/license');
            } else
            // If this Licensee only has ONE Site, and it's not the same one we have selected (we've likely switched Licensee), Select it
            if (data.result.licensee.sites.length === 1 && state.site?.id !== data.result.licensee.sites[0].id) {
                selectSite(user_id, data.result.licensee.sites[0].display_id, licensee_id, redirect, null, true);
            }
            // Unset the Site, since it won't belong to the new licensee (unless we're selecting Site first)
            else if (unset_site) {
                updateGlobalStateItem('site', {
                    id: null,
                    display_id: null,
                    loading: false
                });
            }
            updateGlobalStateItem('licensee.loading', false);

            if (redirect) {
                navigate("/");
            }
        });
    }

    async function fetchMyLicensees(user_id) {
        updateGlobalStateItem('loading', true);
        await api.post("licensees/me", {
            body: JSON.stringify({
               user_id: user_id
            })
        })
        .then(async (data) => {
            // Alphabetise the List
            data.result.licensee_user_roles.sort(function(a,b) {
                    if (a.licensee['company_name'].toLowerCase() < b.licensee['company_name'].toLowerCase()) return -1;
                    if (b.licensee['company_name'].toLowerCase() < a.licensee['company_name'].toLowerCase()) return 1;
                    return 0;
            });
            updateGlobalStateItem('user.licensee_user_roles', data.result.licensee_user_roles);
            if (data.result.licensee_user_roles.length === 1) {
                await selectLicensee(user_id, data.result.licensee_user_roles[0].licensee_id, false, true);
            }
            updateGlobalStateItem('loading', false);
        });
    }

    // Site Functions
    async function selectSite(user_id, site_id, licensee_id, redirect = false, callback = null, basic = false) {
        updateGlobalStateItem('site.loading', true);

        // Fetch Site Details
        await api.post("sites/fetch",{
            body: JSON.stringify({
               user_id: user_id,
               site_id: site_id,
               licensee_id: licensee_id
            })
        })
        .then(async (data) => {
            // Store In State
            updateGlobalStateItem('site', data.result.site);
            updateGlobalStateItem('user.last_accessed_site_id', site_id);

            if (!basic && data.result.site.licensee_id !== state.licensee.id) {
                await selectLicensee(user_id, data.result.site.licensee_id, false, false);
            } else {
                if (redirect) {
                    navigate("/sites/" + site_id);
                }
            }

            updateGlobalStateItem('site.loading', false);

            // Blank Site Map
            updateGlobalStateItem('sitemap.data', {});
            updateGlobalStateItem('sitemap.loading', true);

            // If a callback is passed through, action it here.
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    // Add New Site to Licensee
    function addSiteToLicensee(site) {
        let licenseeSites = [...state.licensee.sites];
        licenseeSites.push(site);
        // Alphabetise the List
        licenseeSites.sort(function(a,b) {
                if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1;
                if (b['name'].toLowerCase() < a['name'].toLowerCase()) return 1;
                return 0;
        });
        updateGlobalStateItem('licensee.sites', licenseeSites);
    }

    // Update a Licensee Details
    function updateLicensee(licensee_id, field, value, entire_object = false) {
        // Update the Licensee in the Users' Licensee List
        let licensees = [...state.user.licensee_user_roles];
        licensees.map((licensee_data, index) => {
            if (licensee_data.licensee_id === licensee_id) {
                if (entire_object) {
                    // Loop and replace the content of the Object
                    for (var key of Object.keys(value)) {
                        licensees[index]['licensee'][key] = value[key];
                    }
                } else {
                    licensees[index]['licensee'][field] = value;
                }
            }
            return true;
        });
        // Re-Alphabetise the List
        licensees.sort(function(a,b) {
                if (a.licensee['company_name'].toLowerCase() < b.licensee['company_name'].toLowerCase()) return -1;
                if (b.licensee['company_name'].toLowerCase() < a.licensee['company_name'].toLowerCase()) return 1;
                return 0;
        });
        updateGlobalStateItem('user.licensee_user_roles', licensees);

        // If the currently selected Licensee matches this one, also update it.
        if (state.licensee.id === licensee_id) {
            let cloneLicensee = {...state.licensee};
            if (entire_object) {
                // Loop and replace the content of the State
                for (var key of Object.keys(value)) {
                    cloneLicensee[key] = value[key]
                }
            } else {
                cloneLicensee[field] = value;
            }
            updateGlobalStateItem('licensee', cloneLicensee);
        }
    }

    function replaceUserState(user) {
        updateGlobalStateItem('user', user);
    }

    return (
        <GlobalContext.Provider value = {{
            loading: state.loading,
            licensee: state.licensee,
            site: state.site,
            sitemap: state.sitemap,
            user: state.user,
            addSiteMapTier,
            removeSiteMapTier,
            removeSiteMapSiteItem,
            updateGlobalStateItem,
            logUserIn,
            logUserOut,
            selectLicensee,
            selectSite,
            addSiteToLicensee,
            updateLicensee,
            replaceUserState,
            fetchMyLicensees
        }}> 
            {children}
        </GlobalContext.Provider>
    );
};

// React
import React, {
    lazy,
    Suspense,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";

// Other Imports
import Cookies from 'universal-cookie';

// Ant Design Components
import Spin from "antd/lib/spin";

// Context / State
import {GlobalContext} from 'context/GlobalState';

// Helpers
import api from 'helpers/api';
import config from 'helpers/config';

// Styles
import 'intro.js/introjs.css';
import ('styles/App.css' /* webpackChunkName: "App" */);

// Content
const Main = lazy(() => import('Main' /* webpackChunkName: "Main" */).catch(e => window.location.reload()));

const App = () => {
    const [loadingFromCookie, setLoadingFromCookie] = useState(false);
    const loadingDiv = <div></div>;
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {selectLicensee, selectSite, logUserIn, logUserOut, user} = useContext(GlobalContext);
    const locationArray = useLocation().pathname.substring(1).split( '/' );

    useEffect(() => {
        async function loadUserDataFromState() {
            /**
             * Load the User from their previously stored Access Token Cookie
             */
            if (typeof(cookies.get(config.environmentShort + 'AT')) !== 'undefined') {
                setLoadingFromCookie(true);
                // Since we have a stored token, hitting the /users/login path will return data if the token is Valid.
                await api.post("users/login")
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if (typeof res === 'string') {
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if (res.status === 'NOK' || res.result.success === false) {
                        logUserOut();
                        cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
                        localStorage.clear();
                        setLoadingFromCookie(false);
                        navigate('/');
                    } else {
                        logUserIn(res.result.user);
                        setLoadingFromCookie(false);

                        // If User has no Licensees, redirect to /no-licensee
                        // @todo: Landing Page for this - They might be an invited user who has not accepted anything yet?
                        if (res.result.user.licensee_user_roles.length === 0) {
                            navigate('/no-licensee');
                        } else {
                            // Don't redirect to home screen if we're on specific routes.
                            let noRedirect = [
                                'items',
                                'users',
                                'wb',
                                'onboarding',
                                'license',
                                'help'
                            ];

                            // If accessing a Site directly, Select it, and it's Licensee if possible.
                            if (locationArray[0] === 'sites' && !!locationArray[1] && locationArray[1] !== 'add') {
                                selectSite(res.result.user.id, locationArray[1], res.result.user.last_accessed_licensee_id);
                            } else if (!!res.result.user.last_accessed_site_id) {
                                selectSite(res.result.user.id, res.result.user.last_accessed_site_id, res.result.user.last_accessed_licensee_id, !noRedirect.includes(locationArray[0]));
                            } else {
                                // Use their most recent Licensee
                                if (!!res.result.user.last_accessed_licensee_id) {
                                    selectLicensee(res.result.user.id, res.result.user.last_accessed_licensee_id, !noRedirect.includes(locationArray[0]));
                                } else {
                                    // Default to the first one in the array
                                    selectLicensee(res.result.user.id, res.result.user.licensee_user_roles[0].licensee_id, !noRedirect.includes(locationArray[0]));
                                }
                            }
                        }
                    }
                })
                .catch(() => {
                    logUserOut();
                    cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
                    localStorage.clear();
                    setLoadingFromCookie(false);
                    navigate('/');
                });
            }
        }
        loadUserDataFromState();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // When a User ID changes (i.e. we log in), fetch the Site Item Statuses
    const [siteItemStatuses, setSiteItemStatuses] = useState([]);
    useEffect(() => {
        if (user.id !== null) {
            fetchSiteItemStatuses();
        }
    }, [user.id]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchSiteItemStatuses = () => {
        // Fetch Site Item Statuses
        api.get("site_items/statuses")
        .then(data => {
            // Save to State
            setSiteItemStatuses(data.result.statuses);
        });
    };

    return (
        <Suspense
            fallback={loadingDiv}
        >
            <Spin
                delay={100}
                tip="Welcome back! Logging you in..."
                size="large"
                spinning={loadingFromCookie}
                wrapperClassName={loadingFromCookie ? 'spin-blur-background' : ''}
            >
                <Main
                    loadingDiv={loadingDiv}
                    siteItemStatuses={siteItemStatuses}
                />
            </Spin>
        </Suspense>
    );
};

export default App;
